<template>
  <reactive-base
      app="member_index"
      :credentials="user.user.es_username + ':' + user.user.es_password"
      :url="elasticUrl"
  >
    <ReactiveComponent componentId="DataControllerSensor">
      <div slot-scope="{ setQuery }">
        <custom-member-results :setQuery="setQuery" :query="query"/>
      </div>
    </ReactiveComponent>
    <reactive-list
        :showResultStats="false"
        componentId="SearchResult"
        className="result-list-container"
        :pagination="true"
        dataField="_score"
        sortBy="desc"
        :URLParams="true"
        :from="0"
        :size="15"
        :react="{ and: ['DataControllerSensor'] }"
    >
      <table class="w-100 clickable-vuetable vuetable" slot="render" slot-scope="{ loading, error, data }">
        <thead>
        <tr>
          <th
              v-for="field in fields"
              :key="field.key"
              :width="field.width + '%'"
              :class="{ 'sort-up': isSortField(field.sort_field, true), 'sort-down': isSortField(field.sort_field, false) }"
              @click="changeSortBy(field.sort_field)"
          >
            {{ field.label }}
          </th>
        </tr>
        </thead>
        <tbody>
        <router-link tag="tr" :to="'/members/member/' + item.id + '/overview'" class="cursor-pointer"
                     v-for="item in data" :key="item.id">
          <td v-for="field in fields" :key="'table-' + item.id + '-' + field.key">
            {{ field.key !== 'email' ? item[field.key] : '' }}
            <button v-if="field.key === 'email'" :href="'mailto:' + item[field.key]" class="h-100 btn btn-light">
              <i class="mt-2 fas fa-envelope opacity-40"></i>
            </button>
          </td>
        </router-link>
        </tbody>
      </table>
    </reactive-list>
  </reactive-base>
</template>
<script>
import CustomMemberResults from '@/components/lists/member/CustomMemberResults';

export default {
  components: {
    CustomMemberResults,
  },
  props: {
    query: Object,
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  data() {
    return {
      elasticUrl: process.env.VUE_APP_API_ELASTIC,
      signatures: [],
      fields: [
        {
          key: 'first_name',
          sort_field: 'first_name.raw',
          label: this.$t('labels.first-name'),
          width: 25,
        },
        {
          key: 'last_name',
          sort_field: 'last_name.raw',
          label: this.$t('labels.last-name'),
          width: 25,
        },
        {
          key: 'office',
          sort_field: 'office.raw',
          label: this.$t('labels.victim-support-centre'),
          width: 25,
        },

        {
          key: 'support_list',
          sort_field: 'support_list.raw',
          label: this.$t('labels.supporters'),
          width: 25,
        },
      ],
    };
  },
  mounted() {
    this.$modal.show('send-message-modal');
  },
  methods: {
    pageChange() {
    },

    isSortField(field, direction) {
      if (this.getSortBy === field && this.getSortByOrder === direction) {
        return true;
      } else {
        return false;
      }
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    changeSortBy(value) {
      this.getSortBy = value;
      this.getSortByOrder = !this.getSortByOrder;
      this.updateQueryStringParam('sort', value);
      this.updateQueryStringParam('sortOrder', this.getSortByOrder);
    },
  },
};
</script>
