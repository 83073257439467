<template>
  <modal name="send-message-modal" @closed="close" :scrollable="true" :width="1100" :height="740" :maxHeight="600">
    <div class="clearfix">
      <h4 class="float-left">Lista: {{ data.label }}</h4>
    </div>

    <div class="container bt-1">
      <div class="row">
        <div class="col-12 pt-0 pl-0 pr-0">
          <view-list-member v-if="data.model_type === 'member'" :query="JSON.parse(data.query)"></view-list-member>
          <view-list-local-office v-if="data.model_type === 'localoffice'"
                                  :query="JSON.parse(data.query)"></view-list-local-office>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import BojAPI from '@/api/boj';
import ViewListMember from '@/components/email/ViewListMember';
import ViewListLocalOffice from '@/components/email/ViewListLocalOffice';

export default {
  components: {
    ViewListLocalOffice,
    ViewListMember,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      signatures: [],
    };
  },
  mounted() {
    this.$modal.show('send-message-modal');
  },
  methods: {
    close() {
      this.$modal.hide('send-message-modal');
      this.$emit('closed');
    },
  },
};
</script>
