<template>
  <reactive-base
      app="ticket_local_office"
      :credentials="user.user.es_username + ':' + user.user.es_password"
      :url="elasticUrl"
  >
    <ReactiveComponent componentId="DataControllerSensor">
      <div slot-scope="{ setQuery }">
        <custom-member-results :setQuery="setQuery" :query="query"/>
      </div>
    </ReactiveComponent>
    <reactive-list
        :showResultStats="false"
        componentId="SearchResult"
        className="result-list-container"
        :pagination="true"
        dataField="_score"
        sortBy="desc"
        :URLParams="true"
        :from="0"
        :size="15"
        :react="{ and: ['DataControllerSensor'] }"
    >
      <table class="w-100 clickable-vuetable vuetable" slot="render" slot-scope="{ loading, error, data }">
        <thead>
        <tr>
          <th
              width="33%"
              :class="{ 'sort-up': isSortField('office.raw', true), 'sort-down': isSortField('office.raw', false) }"
              @click="changeSortBy('office.raw')"
          >
            Brottsofferjour
          </th>
          <th
              width="33%"
              :class="{ 'sort-up': isSortField('label.raw', true), 'sort-down': isSortField('label.raw', false) }"
              @click="changeSortBy('label.raw')"
          >
            Lokalkontor
          </th>
          <th
              width="34%"
              :class="{
								'sort-up': isSortField('municipality_list.raw', true),
								'sort-down': isSortField('municipality_list.raw', false),
							}"
              @click="changeSortBy('municipality_list.raw')"
          >
            Kommun
          </th>
        </tr>
        </thead>
        <tbody>
        <router-link tag="tr" :to="'/offices/localoffice/' + item.id + '/overview'" v-for="item in data" :key="item.id"
                     class="cursor-pointer">
          <td v-html="item.office"></td>
          <td v-html="item.label"></td>
          <td v-html="item.municipality_indexed.replace(/,/g, ', ')"></td>
        </router-link>
        </tbody>
      </table>
    </reactive-list>
  </reactive-base>
</template>
<script>
import CustomMemberResults from '@/components/lists/member/CustomMemberResults';

export default {
  components: {
    CustomMemberResults,
  },
  props: {
    query: Object,
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  data() {
    return {
      elasticUrl: process.env.VUE_APP_API_ELASTIC,
      signatures: [],
      fields: [
        {
          key: 'first_name',
          sort_field: 'first_name.raw',
          label: this.$t('labels.first-name'),
          width: 25,
        },
        {
          key: 'last_name',
          sort_field: 'last_name.raw',
          label: this.$t('labels.last-name'),
          width: 25,
        },
        {
          key: 'office',
          sort_field: 'office.raw',
          label: this.$t('labels.victim-support-centre'),
          width: 25,
        },

        {
          key: 'support_list',
          sort_field: 'support_list.raw',
          label: this.$t('labels.supporters'),
          width: 25,
        },
      ],
    };
  },
  mounted() {
    this.$modal.show('send-message-modal');
  },
  methods: {
    pageChange() {
    },

    isSortField(field, direction) {
      if (this.getSortBy === field && this.getSortByOrder === direction) {
        return true;
      } else {
        return false;
      }
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    changeSortBy(value) {
      this.getSortBy = value;
      this.getSortByOrder = !this.getSortByOrder;
      this.updateQueryStringParam('sort', value);
      this.updateQueryStringParam('sortOrder', this.getSortByOrder);
    },
  },
};
</script>
