var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('reactive-base',{attrs:{"app":"ticket_local_office","credentials":_vm.user.user.es_username + ':' + _vm.user.user.es_password,"url":_vm.elasticUrl}},[_c('ReactiveComponent',{attrs:{"componentId":"DataControllerSensor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var setQuery = ref.setQuery;
return _c('div',{},[_c('custom-member-results',{attrs:{"setQuery":setQuery,"query":_vm.query}})],1)}}])}),_c('reactive-list',{attrs:{"showResultStats":false,"componentId":"SearchResult","className":"result-list-container","pagination":true,"dataField":"_score","sortBy":"desc","URLParams":true,"from":0,"size":15,"react":{ and: ['DataControllerSensor'] }},scopedSlots:_vm._u([{key:"render",fn:function(ref){
var loading = ref.loading;
var error = ref.error;
var data = ref.data;
return _c('table',{staticClass:"w-100 clickable-vuetable vuetable"},[_c('thead',[_c('tr',[_c('th',{class:{ 'sort-up': _vm.isSortField('office.raw', true), 'sort-down': _vm.isSortField('office.raw', false) },attrs:{"width":"33%"},on:{"click":function($event){return _vm.changeSortBy('office.raw')}}},[_vm._v(" Brottsofferjour ")]),_c('th',{class:{ 'sort-up': _vm.isSortField('label.raw', true), 'sort-down': _vm.isSortField('label.raw', false) },attrs:{"width":"33%"},on:{"click":function($event){return _vm.changeSortBy('label.raw')}}},[_vm._v(" Lokalkontor ")]),_c('th',{class:{
								'sort-up': _vm.isSortField('municipality_list.raw', true),
								'sort-down': _vm.isSortField('municipality_list.raw', false),
							},attrs:{"width":"34%"},on:{"click":function($event){return _vm.changeSortBy('municipality_list.raw')}}},[_vm._v(" Kommun ")])])]),_c('tbody',_vm._l((data),function(item){return _c('router-link',{key:item.id,staticClass:"cursor-pointer",attrs:{"tag":"tr","to":'/offices/localoffice/' + item.id + '/overview'}},[_c('td',{domProps:{"innerHTML":_vm._s(item.office)}}),_c('td',{domProps:{"innerHTML":_vm._s(item.label)}}),_c('td',{domProps:{"innerHTML":_vm._s(item.municipality_indexed.replace(/,/g, ', '))}})])}),1)])}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }